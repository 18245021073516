import React from 'react';
import { CardsSuscripcion, SlidePrincipal,SectionFrase, SectionQuienes} from '../_components';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import {Row,Col,Button,Container,Card,ListGroup} from 'react-bootstrap';
import { Parallax, Background } from 'react-parallax';
import ScrollUpButton from "react-scroll-up-button";
import "animate.css/animate.min.css";
import 'react-accessible-accordion/dist/fancy-example.css';
class HomePage extends React.Component {

    constructor(props){
        super(props);     
    }

    state = {
    };

    componentDidMount() {  
        
       // if(this.props.equipos.misEquipos===undefined)this.props.getEquiposPU();
    };


    render() {
        return (
        
        <div>
          <ScrollUpButton 
          ContainerClassName="AnyClassForContainer shadow"
          EasingType="easeInQuad"/>
          
          <SlidePrincipal/>           
         
          <SectionQuienes/>
          
          <Container fluid className="empresa">
                <CardsSuscripcion/>  
          </Container>
          
          <SectionFrase/>
   </div>

        );
    }
}

function mapState(state) {
   
    return {};
}

const actionCreators = {
   // getUsers: userActions.getAll,
   // getEquiposPU: equipoActions.getEquiposPU
}

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };