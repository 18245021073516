import Cookies from 'universal-cookie';

export function refreshToken() {
    const cookies = new Cookies();
    return cookies.get('refresh_token');
}

export function authHeader() {
    const cookies = new Cookies();
    return {'Authorization':`Bearer ${cookies.get('access_token')}`};
}

export function tengoRol(roles) {
    let tengoRol=false;

    const cookies = new Cookies();
    const rolesCookie = cookies.get('roles');
    if(rolesCookie!==undefined)tengoRol = rolesCookie.some(rol=>roles.includes(rol));

    return tengoRol;
}
