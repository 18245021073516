import React from 'react';
import {ListGroup} from 'react-bootstrap';

export default class CaracteristicasSuscripcion extends React.Component {
    
    render(){
        const {tipoSuscripcion} = this.props.suscripcion;

        let caracSi =['Vinculación con dispositivos','Seis widgets','Vistas personalizadas'];
        let caracNo = [];

        const accion='Acciones programadas';
        const evento='Eventos automatizados';
        const telegram = 'Telegram';
        const programa= 'Programación';
        const geolo = 'Geolocalizacion';


        switch(tipoSuscripcion.id) {
            case 1:
                caracNo=[accion,evento,telegram,programa,geolo];
                break;
            case 2:
                caracSi=caracSi.concat([geolo,telegram]);
                caracNo=[accion,evento,programa];
                break;
            case 3:
                caracSi=caracSi.concat([accion,evento,geolo,telegram]);
                caracNo=[programa];
                break;
            case 4:
                caracSi=caracSi.concat([accion,evento,geolo,telegram,programa]);    
                break;        
        }


        return (
                <>
                    
                    {caracSi && caracSi.map((caracSiItem, ind)=>     
                        <ListGroup.Item className="text-left">
                            <small className="">
                                <span className="material-icons material-icons-outlined text-color">check</span> 
                                {caracSiItem}</small>
                        </ListGroup.Item>
                    )}
                    {caracNo && caracNo.map((caracNoItem, ind)=>                      
                        <ListGroup.Item className="text-left">
                            <small className="text-muteds">
                                <span className="material-icons material-icons-outlined">clear</span> 
                                {caracNoItem}
                            </small>
                        </ListGroup.Item>
                    )}
                </>
        );
    }
}

