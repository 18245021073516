import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Row,Col,Button,Container,Card,ListGroup,ListGroupItem} from 'react-bootstrap';
import { publicaActions } from '../_actions';


class VideosTutorial extends React.Component {

    state = {
    };

    componentDidMount() {  
        if(this.props.tutoriales===undefined)this.props.traerTutoriales(); 
    };


    render() {
        const {tutoriales} = this.props;
        console.log('tutoriales',tutoriales)
        return (
        
            <div>          
            
                <div className="page-header bg-gradient-primary-to-secondary">
                    <div className="title-header">Videos Tutoriales</div>
                </div>
                <Container fluid>    
                    <Row className="mt-5 mb-5">
                        {tutoriales && tutoriales.map((tuto, ind)=>
                            <Col key={tuto.id} lg={4}>
                                <Card className="shadow">
                                <iframe src={tuto.linkYoutube} width="100%" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                <Card.Body>
                                    <Card.Title>{tuto.titulo}</Card.Title>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroupItem>{tuto.descripcion}</ListGroupItem>
                                </ListGroup>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>         
            </div>

        );
    }
}

function mapState(state) {  
    const { tutoriales } = state.publico;
    return { tutoriales };
}

const actionCreators = {
    traerTutoriales: publicaActions.traerTutoriales
}

const connectedVideosTutorial = connect(mapState, actionCreators)(VideosTutorial);
export { connectedVideosTutorial as VideosTutorial };