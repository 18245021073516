import React from 'react';
import { connect } from 'react-redux';
import {Row,Col,Button,Container,Card,ListGroup} from 'react-bootstrap';
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

class SectionQuienes extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }
    
    
    state = {
        show: false,
        showDetalle:false
    };

    componentDidUpdate(){
        
    }
    handleClose = () => {
        this.setState({show: false,showDetalle:false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    componentDidMount(){
    }

    render(){
        const { user } = this.props;
        return (
            <div>
                         <Container>   
            <Row className="justify-content-center mt-5">
                <Col xs={12}>
                <ScrollAnimation animateIn='fadeIn'> 
                <h2 className="font-weight-bold">¿Qué hacemos en IOTECH AR?</h2>
                    <p className="lead font-weight-light mt-3 text-muted"><span className="material-icons text-lima">check_circle</span> Nos ocupamos de lo tecnología, para que te ocupes de hacer crecer tu negocio.</p>
                    <p className="lead font-weight-light mt-3 text-muted"><span className="material-icons text-cielo">check_circle</span> Te ayudamos a construir soluciones IoT.</p>
                    <p className="lead font-weight-light mt-3 text-muted"><span className="material-icons text-lima">check_circle</span> Monitoreo en tiempo real, alertas, programación de tareas y eventos configurables en una
            única plataforma.</p>
                    <p className="lead font-weight-light mt-3 text-muted"><span className="material-icons text-cielo">check_circle</span> Accede a tus equipos compartilos con tus clientes, y escalá soluciones con tus
            conocimientos.</p>
                    <p className="lead font-weight-light mt-3 text-muted"><span className="material-icons text-lima">check_circle</span> Ayudamos a las personas que desarrollen IoT a introducirlas rápidamente en el mercado,
            para que sus ideas sean nuevas e innovadoras utilizando nuestra plataforma de IoT, que
            incluso se adapta a principiantes.</p>
              
                </ScrollAnimation>
                </Col>
                
                <Col xs={12} className="mt-4"> 
                  
                      <div class="containercard">
                      <Row>
                  <Col className="text-center " xs={12} lg={4}>
                  <ScrollAnimation animateIn='fadeIn'  delay={100}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-4 txt-124 text-lima"></span> 
                            <h3 className="text-dark">Plataforma IoT</h3>
                            </div>
                        </div>
                        <div className="overlay bg-lima"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">Plataforma IoT</h4>
                        <p>Conoce nuestro sistema especializado en IoT , encontrarás una plataforma web y móvil
            que te brindara herramientas , ejemplos y guías para el desarrollo de tus proyectos y
            conexión desde cualquier lugar del mundo.Integra tu ecosistema o infraestructura y tus soluciones para recopilar, almacenar y
            consultar datos recopilados por sensores distribuidos en todo el mundo.</p>
                        </div>
                        </div>
                    </ScrollAnimation>
                    </Col>

                    <Col className="text-center" xs={12} lg={4}>
                    <ScrollAnimation animateIn='fadeIn'  delay={300}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-3 txt-124 text-uva"></span>
                            <h3 className="text-dark">Agricultura inteligente</h3>
                            </div>
                        </div>
                        <div className="overlay bg-uva"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">Agricultura inteligente</h4>
                        <p>Monitorea los procesos en cultivos y campos, toma decisiones estratégicas de manera
            remota monitoreando la calidad ambiental para estudiar el comportamiento y hacer los
            ajustes necesarios a las condiciones actuales para aumentar la cantidad y calidad de los
            productos</p>
                        </div>
                        </div>
                   
                    </ScrollAnimation>
                    </Col>
                    <Col className="text-center" xs={12} lg={4}>
                    <ScrollAnimation animateIn='fadeIn'  delay={300}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-1 txt-124 text-cielo"></span>
                            <h3>Monitoreo ambiental inteligente</h3>
                            </div>
                        </div>
                        <div className="overlay bg-cielo"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">Monitoreo ambiental inteligente</h4>
                        <p>Monitorea datos ambientales reportados directamente de tus sensores, mantén seguro
            tus ambientes generando áreas libres de COVID, controla concentración de monóxido de
            carbono, pureza y calidad del aire, emite alarmas y administra y genera eventos para
            proteger a las personas.</p>
                        </div>
                        </div>
                   
                    </ScrollAnimation>
                    </Col>
                    <Col className="text-center" xs={12} lg={4}>
                    <ScrollAnimation animateIn='fadeIn'  delay={700}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-6 txt-124 text-recursos"></span>
                            <h3>Pymes 4.0</h3>
                            </div>
                        </div>
                        <div className="overlay bg-recursos"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">Pymes 4.0</h4>
                        <p>Nuestra plataforma permite que las operaciones se ejecuten con una mínima intervención
            manual y una alta fiabilidad.
            Genera flujos de trabajo automatizados, sincroniza las actividades, el seguimiento y la
            programación minimizando el consumo de energía y optimizado el tiempo de actividad y
            la calidad, que te permitirán reducir los costos, convierte tu PyME en una PyME 4.0. </p>
                        </div>
                        </div>
                   
                    </ScrollAnimation>
                    </Col>
                    <Col className="text-center" xs={12} lg={4}>
                    <ScrollAnimation animateIn='fadeIn'  delay={900}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-5 txt-124 text-fucsia"></span>
                            <h3>Industria 4.0</h3>
                            </div>
                        </div>
                        <div className="overlay bg-fucsia"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">Industria 4.0</h4>
                        <p>Integra los dispositivos pre existentes en tu empresa de manera ágil, compartí los mismos a tus colaboradores.
Implementá soluciones de manera segura, con el soporte personalizado de especialistas en industria.
No quedes atras en la cuarta revolucion industrial. </p>
                        </div>
                        </div>
                   
                    </ScrollAnimation>
                    </Col>
                    <Col className="text-center" xs={12} lg={4}>
                    <ScrollAnimation animateIn='fadeIn'  delay={1100}>  
                        <div className="cards mb-5">
                        <div className="imgBx">
                            <div>
                            <span className="icon-2 txt-124 text-maiz"></span>
                            <h3>SmartCities</h3>
                            </div>
                        </div>
                        <div className="overlay bg-maiz"></div>
                        <div className="content">
                        <h4 className="font-weight-bold">SmartCities</h4>
                        <p>Automatiza, monitorea y controla dispositivos en ciudades de manera organizada. Colabora con el desarrollo sostenible 
en tu comunidad, y brinda asistencia especializada a ciudades. Con nuestra App, es posible resolver de manera rápida la 
solución de tu interés, brindado soluciones eficientes, orientadas a cada necesidad. </p>
                        </div>
                        </div>
                   
                    </ScrollAnimation>
                    </Col></Row>
                     </div>
                  
                </Col>
            </Row>
          </Container>
          <div className="svg-border-rounded2 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
          </div>
            </div>
        );
    }
}

function mapState(state) {
 
    return {  };
}

const actionCreators = {    
}

const connectedSectionQuienes = connect(mapState, actionCreators)(SectionQuienes);
export { connectedSectionQuienes as SectionQuienes };