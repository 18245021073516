import http from '../_helpers/http.service';

export const publicoService = {
    traerNoticias,
    traerTutoriales,
    traerSuscripciones,
    traerImagen
};

function traerImagen(values) {    
    var url = '/noticia/traerImagen';
    return http.post(url,JSON.stringify(values));    
}

function traerTutoriales() {    
    var url = '/tutoriales/traerTutoriales';
    return http.get(url);    
}

function traerSuscripciones() {    
    var url = '/suscripciones/listado';
    return http.get(url);    
}

function traerNoticias() {    
    var url = '/noticias/traerNoticias';
    return http.get(url);    
}