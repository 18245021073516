import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { publico } from './publica.reducer';



const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  publico
});

export default rootReducer;