import React from 'react';
import { connect } from 'react-redux';
import "animate.css/animate.min.css";
import {Row,Col,Button,Container,Card,ListGroup} from 'react-bootstrap';
import { Parallax, Background } from 'react-parallax';
import ScrollAnimation from 'react-animate-on-scroll';

class SectionFrase extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }
    
    
    state = {
        show: false,
        showDetalle:false
    };

    componentDidUpdate(){
        
    }
    handleClose = () => {
        this.setState({show: false,showDetalle:false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    componentDidMount(){
    }

    render(){
        const { user } = this.props;
        const appName=process.env.PUBLIC_URL;
        return (
            <div>
                <Parallax
      className="bg-gradient-primary-to-secondary"
      blur={{ min: -55, max: 65 }}
      bgImage={appName+'/images/800x400.png'}
      bgImageAlt="Fondo"
      strength={200}
    >
     
       <ScrollAnimation animateIn='bounceInDown'>  
      <Row className="justify-content-center mt-5">
        <h2 className="text-white txt-slogan txt-42"><span className="font-weight-light">CONECTANDO EL</span> <b>FUTURO</b>...</h2> 
      </Row>
  </ScrollAnimation>
      <div style={{ height: '300px' }} />
</Parallax>
            </div>
        );
    }
}

function mapState(state) {
 
    return {  };
}

const actionCreators = {    
}

const connectedSectionFrase = connect(mapState, actionCreators)(SectionFrase);
export { connectedSectionFrase as SectionFrase };