import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Row,Col,Button,Container,Card,ListGroup,ListGroupItem} from 'react-bootstrap';
import { publicaActions } from '../../_actions';
import { publicoService } from '../../_services';


class Blog extends React.Component {

    state = {
    };

    componentDidMount() {  
        if(this.props.noticias===undefined)this.props.traerNoticias();
    };

    buscarImagen=(id)=>{
        let imagen;
        let values = {
            id:id
        }
        publicoService.traerImagen(values)
        .then(
            data => { 
                imagen = data.imagen?.bytes;
            },
            error => {    
               
            }
        );

        return imagen;

    }


    render() {
        const {noticias} = this.props;
      
        return (
        
            <div> 
                <div className="page-header bg-gradient-primary-to-secondary">
                    <div className="title-header">Blog</div>
                </div>
                <Container fluid>    
                    <Row className="mt-5 mb-5">
                        {noticias && noticias.map((noti, ind)=>
                        <Col key={noti.id} lg={4}>
                               
                                    <Card className="shadow">
                                        <img src={this.buscarImagen(noti.imagenPrincipal)} />
                                        <Card.Body>
                                            <Link to={'/blogDetalle?id='+noti.id}>
                                                <Card.Title className="text-dark">aca el tiitulo{noti.titulo}</Card.Title>
                                            </Link>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem>{noti.copete}</ListGroupItem>
                                        </ListGroup>
                                    </Card>
                               
                        </Col>
                        )}
                    </Row>
                </Container>         
            </div>

        );
    }
}

function mapState(state) {
    const { noticias } = state.publico;
    return { noticias };
}

const actionCreators = {
    traerNoticias: publicaActions.traerNoticias
}

const connectedBlog = connect(mapState, actionCreators)(Blog);
export { connectedBlog as Blog };