import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import {Row,Col,Button,Container,Card,ListGroup} from 'react-bootstrap';
import "animate.css/animate.min.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  


class SlidePrincipal extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }
    
    
    state = {
        show: false,
        showDetalle:false
    };

    componentDidUpdate(){
        
    }
    handleClose = () => {
        this.setState({show: false,showDetalle:false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    componentDidMount(){
    }

    render(){
        const { user } = this.props;
        const appName=process.env.PUBLIC_URL;
        return (
            <div>
        <div className="page-header bg-gradient-primary-to-secondary">
            <Carousel fade>
                <Carousel.Item interval={1000} >
                <img
                    className="d-none d-lg-block w-100"
                    src={appName+'/images/800x400.png'}
                    alt="Third slide"
                    />
                    <img
                    className="d-block d-lg-none d-md-none d-xl-none "
                    src={appName+'/images/800x400M.png'}
                    alt="Third slide"
                    />
                  <Carousel.Caption className="">
                    <div className="img-slide bounceInDown">
                    <img
                    className="top-98"
                    src={appName+"/images/logo.svg"}
                    alt="Logo IOTECH"
                    />
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                    <img
                    className="d-none d-lg-block w-100"
                    src={appName+'/images/800x400.png'}
                    alt="Third slide"
                    />
                    <img
                    className="d-block d-lg-none d-md-none d-xl-none "
                    src={appName+'/images/800x400M.png'}
                    alt="Third slide"
                    />
                  <Carousel.Caption >
                      <Row>
                          <Col sm={12} lg={6} className="slideInRight text-left">
                          <h3 className="text-white font-weight-bold">PWA IOTECH AR</h3>
                          <p className="text-white">Progressive Web APPs (PWA) </p>
                          <a href="https://app.iotech.cloud/">
                              <button href="https://app.iotech.cloud/" className="btns btns-recursos txt-29 flash" size="lg">Probala <b>AHORA! </b> </button>
                          </a>
                          </Col>
                          <Col sm={12} lg={6} className="img-slide-m slideInDown">
                          <img
                            src={appName+'/images/mobile.png'}
                            alt="Iotech App Descripción"
                        />
                          </Col>
                      </Row>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={1000}>
                <img
                    className="d-none d-lg-block w-100"
                    src={appName+'/images/800x400.png'}
                    alt="Third slide"
                    />
                    <img
                    className="d-block d-lg-none d-md-none d-xl-none "
                    src={appName+'/images/800x400M.png'}
                    alt="Third slide"
                    />
                <Carousel.Caption>
                  <h3 className="font-weight-bold mt-3">Nos ocupamos de la tecnología, para que te ocupes de hacer crecer tu negocio</h3>
                  <p>Monitoreo en tiempo real, alertas, programación de tareas y eventos configurables en una
                  única plataforma</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            <div className="svg-border-rounded text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
            </div>
          </div>   
            </div>
        );
    }
}

function mapState(state) {
 
    return {  };
}

const actionCreators = {    
}

const connectedSlidePrincipal = connect(mapState, actionCreators)(SlidePrincipal);
export { connectedSlidePrincipal as SlidePrincipal };