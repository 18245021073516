import React from 'react';
import { connect } from 'react-redux';
import {Navbar,Nav,Container} from 'react-bootstrap';
import Logo from './Logo';
import {Link } from 'react-router-dom';


class NavBar extends React.Component {
    listener = null;
    state = {
      nav:false
    }
    componentDidMount() {
       window.addEventListener("scroll", this.handleScroll);
     }
     componentWillUnmount() {
        window.removeEventListener('scroll');
      }
     handleScroll= () => {
       if (window.pageYOffset > 140) {
           if(!this.state.nav){
             this.setState({ nav: true });
           }
       }else{
           if(this.state.nav){
             this.setState({ nav: false });
           }
       }
  
     }

    render(){
        const { user } = this.props;
        return (
            <Navbar className={`${this.state.nav && 'Nav__black'}`} sticky="top" collapseOnSelect expand="lg"  variant="dark" >
              <Container fluid>
                  <Link to ={'/home'}>
                    <Navbar.Brand >
                      <Logo/>
                    </Navbar.Brand>  
                  </Link> 
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    {false && 
                    <>
                    <Link className="mr-4 font-weight-bold" to={"/blog"}>Blog</Link>
                    <Link className="mr-4 font-weight-bold" to={"/videos"}>Docs</Link>
                    </>}
                  </Nav>
                  <Nav>
                    <Nav.Link eventKey={2} href="https://app.iotech.cloud/">
                      <button type="button" className="btns btns-arandano bg-gradient-evento" size="lg"> Probala <b>AHORA! </b></button>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>                
            </Navbar>
        );
    }
}

function mapState(state) {
 
    return {  };
}

const actionCreators = {    
}

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };