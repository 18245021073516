import axios from 'axios';
import Cookies from 'universal-cookie';
import { refreshToken } from '../_helpers';

const apiUrl=process.env.REACT_APP_API_URL;

class Request {
    constructor() {
        this.baseURL = apiUrl;
        this.isRefreshing = false;
        this.failedRequests = [];
        this.client = axios.create({
            baseURL: apiUrl,
            headers: {
            }
        });
        this.onRequestFailure = this.onRequestFailure.bind(this);
        this.client.interceptors.request.use(this.beforeRequest);
        this.client.interceptors.response.use(
            this.onRequestSuccess, 
            this.onRequestFailure);
    }

    beforeRequest=(request)=> {

        //const cookies = new Cookies();
       // const access_token = cookies.get('access_token');
        request.headers={
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        return request;
    }

    onRequestSuccess=(response)=> {
        if(response.data && response.data.status===2) return Promise.reject(response.data)
        return response.data;
    }

    async onRequestFailure(err) {
        let parseError = {}

        if (!err.response) {
            parseError={
                codigo:3,
                message:'No se ha podido establecer una conexión con el servidor, intente nuevamente en unos segundos'
            }
        } else {
            const { response } = err;
            if (response.status === 401 && err && err.config && !err.config.__isRetryRequest) {
                if (this.isRefreshing) {
                    try {
                        const token = await new Promise((resolve, reject) => {
                            this.failedRequests.push({ resolve, reject });
                        });
                        err.config.headers.Authorization = `Bearer ${token}`;
                        return this.client(err.config);
                    }
                    catch (e) {
                        return e;
                    }
                }
                this.isRefreshing = true;
                err.config.__isRetryRequest = true;
                return new Promise((resolve, reject) => {
                    this.refrescarToken().then((token) => {
                        err.config.headers.Authorization = `Bearer ${token}`;
                        this.isRefreshing = false;
                        this.processQueue(null, token);
                        resolve(this.client(err.config));
                    }).catch((e) => {
                        this.processQueue(e, null);
                        reject(err.response);
                    });
                });
            }
            parseError={
                codigo:err.response.status,
                message:err.response.statusText?err.response.statusText:''
            }
        }
        
     
        throw parseError;
    }

    processQueue=(error, token = null)=> {
        this.failedRequests.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        this.failedRequests = [];
    }

    refrescarToken=()=>{
        return new Promise((resolve, reject) => {
            
                let url=apiUrl+'/oauth/access_token?grant_type=refresh_token&refresh_token='+refreshToken()            
                let res = fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrer: 'no-referrer'
                }).then(res => res.json()).then(user => {               
                    const cookies = new Cookies();
                    cookies.set('roles', JSON.stringify(user.roles), { path: '/',maxAge: 2595600});
                    cookies.set('access_token', JSON.stringify(user.access_token), { path: '/',maxAge: 2595600});
                    cookies.set('refresh_token', JSON.stringify(user.refresh_token), { path: '/',maxAge: 2595600});                 
                    resolve(user.access_token);
                });
            
            });
    }
}


const request = new Request();

export default request.client;