import React from 'react';

export default class Logo extends React.Component {
    
    render(){
        const appName=process.env.PUBLIC_URL;
        return (
            <div>
            <img className="d-none d-lg-block d-xl-block"
            src={appName+'/images/logo.svg'}
            alt="Logo IOTECH"
          />
         
          <img className="d-block d-lg-none d-xl-none"
          src={appName+'/images/logot.svg'}
          alt="Logo IOTECH"
        />
        </div>
        );
    }
}