import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import  {history}  from '../_helpers/';
import { alertActions } from '../_actions';
import {Alerta} from  '../_components/Alerta';
import { VideosTutorial } from '../_components/VideosTutorial';
import { HomePage,NavBar,Footer } from '../_components';
import '../bootstrap.min.css';
import { Blog } from '../_components/Blog/Blog';
import { BlogDetalle } from '../_components/Blog/BlogDetalle';
const appName=process.env.REACT_APP_NAME;

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        return (
            <div>
                <Router basename={appName} history={history}>
                    {alert && alert.message &&
                        <Alerta data={alert} />                   
                    }
                    <NavBar/>               
                    <Switch>            
                         
                        <Route exact path={"/home"} component={HomePage} />
                        <Route exact path={"/videos"} component={VideosTutorial} />
                        <Route exact path={"/blog"} component={Blog} />
                        <Route exact path={"/blogDetalle"} component={BlogDetalle} />
                        <Route path="/" component={HomePage} />    
                        <Redirect from="*" to={"/home"} />                           
                       
                    </Switch>
                    <Footer/> 
                </Router>              
            </div>
            
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };