import React from 'react';
import { connect } from 'react-redux';
import {Navbar,NavDropdown} from 'react-bootstrap';
import {userActions} from '../_actions';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';



class Footer extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }
    
    
    state = {
        show: false,
        showDetalle:false
    };

    componentDidUpdate(){
        
    }
    handleClose = () => {
        this.setState({show: false,showDetalle:false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    componentDidMount(){
    }

    render(){
        const { user } = this.props;
        const appName=process.env.PUBLIC_URL;
        return (
            <div>
                <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className="first">
                            <h4>Habilidades</h4>
                            <p> Plataforma IoT</p>
                            <p> Agricultura inteligente</p>
                            <p> Monitoreo ambiental inteligente</p>
                            <p> Pymes 4.0</p>
                            <p> Industria 4.0</p>
                            <p> SmartCities</p>
                        </div>
                    </div>
    
                    <div className="col-md-1 col-xs-12">
                       
                    </div>
    
                    <div className="col-md-7 col-xs-12">
                        <div className="third">
                            <h4> Contacto</h4>
                            <ul>
                            <li className="txt-18"><span className="material-icons align-middle">language</span> <span className="text-primario font-weight-bold">IOT</span>ECH.AR</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
          
            
                <div className="row">
                    <div className="col-12">
                        <div className="line"></div>
                        <div className="second2">
                            <a href="#" target="_blank"> 
                            <img
                    className="top-98"
                    src={appName+'/images/logo.svg'}
                    alt="Logo IOTECH"
                    />
                            </a>
                           
                       <a href="https://app.iotech.cloud/login" target="_blank">    
                            <img
                    className="top-98"
                    src={appName+'/images/logoI4.svg'}
                    alt="Logo IOTECH"
                    /></a>
                      {/* <a href="https://www.instagram.com/edutech.arg/" target="_blank">    
                            <img
                    className="top-98"
                    src={appName+'/images/logoSG.svg'}
                    alt="Logo IOTECH"
                    /></a> */}
                     <a href="https://edutech.education/" target="_blank">    
                            <img
                    className="top-98"
                    src={appName+'/images/logoEdutech.svg'}
                    alt="Logo IOTECH"
                    /></a>
                            
                        </div>
    
                    </div>
                </div>
                </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
 
    return {  };
}

const actionCreators = {    
}

const connectedFooter = connect(mapState, actionCreators)(Footer);
export { connectedFooter as Footer };