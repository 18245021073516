import { publicaConstants } from '../_constants';
import {  publicoService } from '../_services';
import { alertActions } from '.';

export const publicaActions = {
    traerNoticias,
    traerTutoriales,
    traerSuscripciones
};

function traerTutoriales() {
    return dispatch => {
        publicoService.traerTutoriales()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: publicaConstants.TRAER_TUTORIALES, data } }
}

function traerSuscripciones() {
    return dispatch => {
        publicoService.traerSuscripciones()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: publicaConstants.TRAER_SUSCRIPCIONES, data } }
}

function traerNoticias() {
    return dispatch => {
        publicoService.traerNoticias()
            .then(
                data => { 
                    dispatch(success(data));                   
                },
                error => {    
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(data) { return { type: publicaConstants.TRAER_NOTICIAS, data } }
}