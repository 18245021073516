import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Row,Col,Button,Container,Card,ListGroup,ListGroupItem} from 'react-bootstrap';
import { publicaActions } from '../../_actions';
import { publicoService } from '../../_services';
import queryString from 'query-string';
import Parser from 'html-react-parser';


class BlogDetalle extends React.Component {

    constructor(props){      
        super(props);
        let params = queryString.parse(this.props.location.search);        
        const {id} = params; 
        this.state.id = parseInt(id);
 
     }

    state = {
        imagen:undefined
    };

    componentDidMount() {  
        if(this.props.noticias===undefined)this.props.traerNoticias();
    };

    buscarImagen=(id)=>{
        let imagen;
        let values = {
            id:id
        }
        publicoService.traerImagen(values)
        .then(
            data => { 
                this.setState({imagen:data.imagen?.bytes})             
            },
            error => {    
               
            }
        );

        return imagen;

    }


    render() {
        const {noticias} = this.props;
        const {id,imagen} = this.state;
        
        const noticia = noticias?noticias.find(not=>not.id===id):undefined;
        if(!imagen && noticia)this.buscarImagen(noticia.imagenPrincipal)
        return (
        
            <div>                
                <Container fluid>    
                    <Row className="mt-5 mb-5">
                        {noticia?
                        <>
                         {imagen?<img src={imagen} />:'loading imagen'}
                        
                        {noticia.titulo}
                        {noticia.copete}
                        {Parser(noticia.cuerpo)}  

                        </>
                        :
                        'LOADING....'
                        }
                    </Row>
                </Container>         
            </div>

        );
    }
}

function mapState(state) {
    const { noticias } = state.publico;
    return { noticias };
}

const actionCreators = {
    traerNoticias: publicaActions.traerNoticias
}

const connectedBlogDetalle= connect(mapState, actionCreators)(BlogDetalle);
export { connectedBlogDetalle as BlogDetalle};