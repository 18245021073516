import React from 'react';
import { connect } from 'react-redux';
import {Row,ListGroup,Tabs,Tab,Col} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
import { publicaActions } from '../_actions';
import CaracteristicasSuscripcion from './Suscripcion/CaracteristicasSuscripcion';
  


class CardsSuscripcion extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }
    
    
    state = {
        show: false,
        showDetalle:false
    };

  contratar=(suscripcion,esGratis)=>{
      const urlAppBase = process.env.REACT_APP_PRINCIPAL_URL;
      window.location.href = urlAppBase+"/login?idSuscripcion="+suscripcion.id+"&esGratis="+esGratis;     
  }

    componentDidUpdate(){
        
    }
    handleClose = () => {
        this.setState({show: false,showDetalle:false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    componentDidMount(){
      if(this.props.suscripciones===undefined)this.props.traerSuscripciones();
    }

    render(){
        let { suscripciones } = this.props;

        if(suscripciones)suscripciones.sort((a, b) => a.costo > b.costo ? 1 : -1)
        console.log(suscripciones)
        return (
            <div>
            
              <Row className="justify-content-center text-center">
              <Col xs={12} className="mt-5 nav-justified tabs-custom mb-5">
                <h3 className="mt-5">Planes</h3>
                <Tabs className="mt-5" defaultActiveKey="emprendedor">
                  <Tab className="mt-5"  eventKey="emprendedor" title="Emprendedor">
                    <div className="w-table mt-5">
                    <div className="w-table-cell">
                      <div className="w-container ">
                      {suscripciones && suscripciones.map((suscripcion, ind)=>
                      <>
                          {suscripcion.claseSuscripcion===1 &&<div className="w-card  shadow fadeIn" key={suscripcion.id}>
                              <div key={suscripcion.id} className="card-headerw">                              
                                    <div className="w-titles mt-3">
                                      {suscripcion.nombre}
                                    </div>
                                    {suscripcion.recomendada && <div className="th_iot"><h6 className="ma-panel-heading"> <i className="fas fa-star"></i> Recomendado</h6></div>}
                                    {(suscripcion.puedeSerGratis || suscripcion.soloGratis) && <div className="th_recursos"><h6 className="ma-panel-heading"><i className="fas fa-angle-double-right mr-2"></i> ¡Probala gratis!</h6></div>}
                                    <div className="w-price mt-5 border-text">
                                      {suscripcion.costo!==0 &&
                                        <>
                                          <small className="prices">(ars)</small>
                                          <NumberFormat prefix={'$'} value= {suscripcion.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                        </>
                                      }
                                    </div>
                                    <div className={suscripcion.soloGratis ?'th_free':'th_fucsia'}>
                                      <div className="container-button" onClick={()=>!suscripcion.soloGratis?this.contratar(suscripcion,false):''}>
                                        <div className="w-button">
                                          {!suscripcion.soloGratis?<><span className="material-icons">add_shopping_cart</span> Contratar</>:''}
                                          {(suscripcion.puedeSerGratis || suscripcion.soloGratis) && 
                                            <div className="" onClick={()=>this.contratar(suscripcion,true)}>
                                              <div className="">
                                                <span>Probar {!suscripcion.soloGratis && suscripcion.cantMesesGratis +' '+ (suscripcion.cantMesesGratis>1?'meses':'mes')} gratis</span>
                                              </div>
                                            </div>
                                            }
                                        </div>
                                      </div>
                                    </div>
                              </div>
                              <div className={suscripcion.soloGratis ?'card-content th_free':'card-content th_fucsia'}>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item className="text-center bg-silver txt-15">
                                        <span className="text-center align-middle material-icons material-icons-outlined">verified_user</span>
                                        {suscripcion.tipoSuscripcion.descripcion}
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <Accordion allowZeroExpanded>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                          <> <span className="font-weight-bold text-center"> Conocé más</span></>
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                        <ListGroup variant="flush">
                                        <ListGroup.Item className="text-left"><small className="text-muted txt-12"><span className="material-icons material-icons-outlined text-color">filter_1</span> MENSUAL </small>
                                        <span className="txt-16 txt-oswald ml-2 ml-2"> 
                                        <NumberFormat prefix={'ars$'} value= {suscripcion.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                        
                                        </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="text-left"><small className="text-muted txt-12"><span className="material-icons material-icons-outlined text-color">filter_3</span> TRIMESTRAL</small>
                                        <span className="txt-16  txt-oswald ml-2 "> 
                                        <NumberFormat prefix={'ars$'} value= {suscripcion.costoTres} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoTresPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                        </span>
                                        </ListGroup.Item>                     
                                        <ListGroup.Item className="text-left"><small className="text-muted txt-12"><span className="material-icons material-icons-outlined text-color">filter_6</span> SEMESTRAL</small>
                                        <span className="txt-16  txt-oswald ml-2 ">
                                          <NumberFormat prefix={'ars$'} value= {suscripcion.costoSeis} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoSeisPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />

                                        </span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="text-left"><small className="text-muted txt-12"><span className="material-icons material-icons-outlined text-color">calendar_today</span> Costo Anual</small>
                                          <span className="txt-16  txt-oswald ml-2 "> 
                                          <NumberFormat prefix={'ars$'} value= {suscripcion.costoDoce} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                            <NumberFormat prefix={'U$D'} value= {suscripcion.costoDocePaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                          </span>
                                        </ListGroup.Item> 
                                            <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">developer_board</span> Cantidad variables</small>
                                              <span className="txt-16  txt-oswald ml-2 "> 
                                                {suscripcion.cantidadSensores}
                                              </span>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">mobile_friendly</span> Cantidad dispositivos</small>
                                              <span className="txt-16  txt-oswald ml-2 "> 
                                              {suscripcion.cantidadDispositivos}
                                              </span>
                                            </ListGroup.Item> 
                                            <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">how_to_reg</span> Cantidad usuarios</small>
                                              <span className="txt-16  txt-oswald ml-2 ">
                                              {suscripcion.cantidadUsuarios}
                                              </span>
                                            </ListGroup.Item>
                                            <CaracteristicasSuscripcion suscripcion={suscripcion}/>
                                          </ListGroup>   
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                              </div>
                            </div>}
                            </>
                      )}      
                      </div>
                </div>
              </div>
                  </Tab>
                  <Tab className="mt-5" eventKey="profesional" title="Profesional">
                <div className="w-table mt-5 mb-5">
                <div className="w-table-cell">
                  <div className="w-container ">
                  {suscripciones && suscripciones.map((suscripcion, ind)=>
                    <>
                    {suscripcion.claseSuscripcion===2 &&
                      <div key={suscripcion.id} className="w-card  shadow fadeIn" key={suscripcion.id}>
                          <div key={suscripcion.id} className="card-headerw">                              
                                    <div className="w-titles mt-3">
                                      {suscripcion.nombre}
                                    </div>
                                    {suscripcion.recomendada && <div className="th_iot"><h6 className="ma-panel-heading"> <i className="fas fa-star"></i> Recomendado</h6></div>}
                                    {(suscripcion.puedeSerGratis || suscripcion.soloGratis) && <div className="th_recursos"><h6 className="ma-panel-heading"><i className="fas fa-angle-double-right mr-2"></i> ¡Probala gratis!</h6></div>}
                                    <div className="w-price mt-5 border-text">
                                      {suscripcion.costo !==0 &&<> 
                                        <small className="prices">(ars)</small><NumberFormat prefix={'$'} value= {suscripcion.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                      </>}
                                    </div>
                                    <div className={suscripcion.recomendada ?'th_uva':'th_recursos'}>
                                      <div className="container-button" onClick={()=>!suscripcion.soloGratis?this.contratar(suscripcion,false):''}>
                                        <div className="w-button">
                                          {!suscripcion.soloGratis?<><span className="material-icons">add_shopping_cart</span> Contratar</>:''}
                                          {(suscripcion.puedeSerGratis || suscripcion.soloGratis) && 
                                            <div className="" onClick={()=>this.contratar(suscripcion,true)}>
                                               <div className="">
                                                <span>Probar {!suscripcion.soloGratis && suscripcion.cantMesesGratis +' '+ (suscripcion.cantMesesGratis>1?'meses':'mes')} gratis</span>
                                              </div>
                                            </div>
                                            }
                                        </div>
                                      </div>
                                    </div>
                              </div>
                          <div  className={suscripcion.recomendada ?' card-content th_uva':'card-content th_recursos'}>
                                <ListGroup variant="flush">
                                  <ListGroup.Item className="text-center bg-silver txt-15">
                                    <span className="text-center align-middle material-icons material-icons-outlined">verified_user</span>
                                    {suscripcion.tipoSuscripcion.descripcion}
                                    </ListGroup.Item>
                                </ListGroup>
                                <Accordion allowZeroExpanded>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                      <> <span className="font-weight-bold text-center"> Conocé más</span></>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <ListGroup variant="flush">
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">filter_1</span> MENSUAL </small>
                                        <span className="txt-18  txt-oswald ml-2 ml-2"> 
                                        <NumberFormat prefix={'ars$'} value= {suscripcion.costo} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                        
                                        </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">filter_3</span> TRIMESTRAL</small>
                                        <span className="txt-16  txt-oswald ml-2 "> 
                                        <NumberFormat prefix={'ars$'} value= {suscripcion.costoTres} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoTresPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                        </span>
                                        </ListGroup.Item>                     
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">filter_6</span> SEMESTRAL</small>
                                        <span className="txt-16  txt-oswald ml-2 ">
                                          <NumberFormat prefix={'ars$'} value= {suscripcion.costoSeis} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                          <NumberFormat prefix={'U$D'} value= {suscripcion.costoSeisPaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />

                                        </span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">calendar_today</span> Costo Anual</small>
                                          <span className="txt-16  txt-oswald ml-2 "> 
                                          <NumberFormat prefix={'ars$'} value= {suscripcion.costoDoce} displayType={'text'} thousandSeparator="." decimalSeparator="," /> - 
                                            <NumberFormat prefix={'U$D'} value= {suscripcion.costoDocePaypal} displayType={'text'} thousandSeparator="." decimalSeparator="," />
                                          </span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">developer_board</span> Cantidad variables</small>
                                          <span className="txt-16  txt-oswald ml-2 "> 
                                            {suscripcion.cantidadSensores}
                                          </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">mobile_friendly</span> Cantidad dispositivos</small>
                                          <span className="txt-16  txt-oswald ml-2 "> 
                                          {suscripcion.cantidadDispositivos}
                                          </span>
                                        </ListGroup.Item> 
                                        <ListGroup.Item className="text-left"><small className="text-muted"><span className="material-icons material-icons-outlined text-color">how_to_reg</span> Cantidad usuarios</small>
                                          <span className="txt-16  txt-oswald ml-2 ">
                                          {suscripcion.cantidadUsuarios}
                                          </span>
                                        </ListGroup.Item>
                                        
                                        <CaracteristicasSuscripcion suscripcion={suscripcion}/>

                                      </ListGroup>   
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>
                          </div>
                        </div>}
                        </>
                  )}      
                  </div>
                </div>
              </div>
                  </Tab>
               
                </Tabs>
                </Col>
                
            </Row>

            
            </div>
        );
    }
}

function mapState(state) {
  const { suscripciones } = state.publico;
  return { suscripciones };
}

const actionCreators = {    
  traerSuscripciones:publicaActions.traerSuscripciones
}

const connectedCardsSuscripcion = connect(mapState, actionCreators)(CardsSuscripcion);
export { connectedCardsSuscripcion as CardsSuscripcion };