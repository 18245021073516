import React from 'react';
import { connect } from 'react-redux';
import {Container,Row,Col} from 'react-bootstrap';

class ContenidoPrincipal extends React.Component {

    constructor(props){
       super(props);
    }

    componentDidMount(){
    }

    render(){
        return (
            <div className="main-content">
                <Container fluid>                    
                     Hola mundo                    
                </Container>
            </div>
        );
    }
}

function mapState(state) {
    const { equipos } = state;
    return { equipos };
}

const actionCreators = {
}

const connectedContenidoPrincipal = connect(mapState, actionCreators)(ContenidoPrincipal);
export { connectedContenidoPrincipal as ContenidoPrincipal };