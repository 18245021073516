import { publicaConstants } from '../_constants';

export function publico(state = {}, action) {
  switch (action.type) {
    case publicaConstants.TRAER_NOTICIAS:
      return {
        noticias: action.data.noticias
      };
      case publicaConstants.TRAER_SUSCRIPCIONES:
      return {
        suscripciones: action.data.suscripciones
      };

      case publicaConstants.TRAER_TUTORIALES:
      return {
        tutoriales: action.data.tutoriales
      };

      
    default:
      return state
  }
}