import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('users'));
const initialState = user ? { user } : {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        listado: action.users
      };
    case userConstants.GETALL_FAILURE:
      return { 
        ...state,
        error: action.error
      };   

    case userConstants.ACTUALIZAR_USUARIO_SUCCESS:
      return { 
        ...state,
        user: action.user
      };    
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
      case userConstants.GETLOGUEADO_SUCCESS:
        // add 'deleting:true' property to user being deleted
        return {
          ...state,
          user:action.user          
        };
      
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
      case userConstants.LOGOUT:
        return {};
    default:
      return state
  }
}